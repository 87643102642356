::-webkit-scrollbar {
  width: 0.625rem;
  height: 0.625rem;
}
::-webkit-scrollbar-track-piece {
  background-color: transparent;
  border-radius: 0.375rem;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0.375rem;
}

/* ::-webkit-scrollbar-thumb:vertical {
  height: 0.3125rem;
  background-color: #f2f2f2;
  border-radius: 0.375rem;
} */

* {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}

/* light-scrollbar && dark-scrollbar */
.light-scrollbar::-webkit-scrollbar,
.dark-scrollbar::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.light-scrollbar::-webkit-scrollbar-button,
.dark-scrollbar::-webkit-scrollbar-button {
  display: none;
  height: 0;
  width: 12px;
}

.light-scrollbar::-webkit-scrollbar-thumb,
.dark-scrollbar::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border: 3px solid rgba(0, 0, 0, 0);
  border-radius: 6px;
  min-height: 36px;
}

.light-scrollbar::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 0%, 0.35);
}

.light-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: hsla(0, 0%, 0%, 0.4);
}

.dark-scrollbar::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 100%, 0.45);
}

.dark-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: hsla(0, 0%, 100%, 0.5);
}
