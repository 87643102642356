/* purgecss start ignore */
@tailwind base;
@tailwind components;

/* purgecss end ignore */

@tailwind utilities;

#root {
  font-size: 13px;
}

.bg-theme-dark {
  --tw-bg-opacity: 0.9;
  background-color: rgba(18, 32, 50, var(--tw-bg-opacity));
}
.bg-theme-1 {
  --tw-bg-opacity: 1;
  background-color: rgba(28, 63, 170, var(--tw-bg-opacity));
}

.bg-theme-2 {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 245, 248, var(--tw-bg-opacity));
}

.bg-theme-3 {
  --tw-bg-opacity: 1;
  background-color: rgba(46, 81, 187, var(--tw-bg-opacity));
}

.bg-theme-4 {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 81, 188, var(--tw-bg-opacity));
}

.bg-theme-5 {
  --tw-bg-opacity: 1;
  background-color: rgba(222, 231, 239, var(--tw-bg-opacity));
}

.bg-theme-6 {
  --tw-bg-opacity: 1;
  background-color: rgba(211, 41, 41, var(--tw-bg-opacity));
}

.bg-theme-7 {
  --tw-bg-opacity: 1;
  background-color: rgba(54, 90, 116, var(--tw-bg-opacity));
}

.bg-theme-8 {
  --tw-bg-opacity: 1;
  background-color: rgba(210, 223, 234, var(--tw-bg-opacity));
}

.bg-theme-9 {
  --tw-bg-opacity: 1;
  background-color: rgba(145, 199, 20, var(--tw-bg-opacity));
}

.bg-theme-10 {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 96, 216, var(--tw-bg-opacity));
}

.bg-theme-11 {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 139, 0, var(--tw-bg-opacity));
}

.bg-theme-12 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 197, 0, var(--tw-bg-opacity));
}

.bg-theme-13 {
  --tw-bg-opacity: 1;
  background-color: rgba(127, 158, 185, var(--tw-bg-opacity));
}

.bg-theme-14 {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 243, 255, var(--tw-bg-opacity));
}

.bg-theme-15 {
  --tw-bg-opacity: 1;
  background-color: rgba(141, 169, 190, var(--tw-bg-opacity));
}

.bg-theme-16 {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 127, 150, var(--tw-bg-opacity));
}

.bg-theme-17 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 239, 217, var(--tw-bg-opacity));
}

.bg-theme-18 {
  --tw-bg-opacity: 1;
  background-color: rgba(216, 248, 188, var(--tw-bg-opacity));
}

.bg-theme-19 {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 243, 255, var(--tw-bg-opacity));
}

.bg-theme-20 {
  --tw-bg-opacity: 1;
  background-color: rgba(36, 73, 175, var(--tw-bg-opacity));
}

.bg-theme-21 {
  --tw-bg-opacity: 1;
  background-color: rgba(40, 78, 178, var(--tw-bg-opacity));
}

.bg-theme-22 {
  --tw-bg-opacity: 1;
  background-color: rgba(57, 94, 193, var(--tw-bg-opacity));
}

.bg-theme-23 {
  --tw-bg-opacity: 1;
  background-color: rgba(214, 225, 255, var(--tw-bg-opacity));
}

.bg-theme-24 {
  --tw-bg-opacity: 1;
  background-color: rgba(46, 81, 187, var(--tw-bg-opacity));
}

.bg-theme-25 {
  --tw-bg-opacity: 1;
  background-color: rgba(198, 212, 253, var(--tw-bg-opacity));
}

.bg-theme-26 {
  --tw-bg-opacity: 1;
  background-color: rgba(232, 238, 255, var(--tw-bg-opacity));
}

.bg-theme-27 {
  --tw-bg-opacity: 1;
  background-color: rgba(152, 175, 245, var(--tw-bg-opacity));
}

.bg-theme-28 {
  --tw-bg-opacity: 1;
  background-color: rgba(26, 56, 159, var(--tw-bg-opacity));
}

.bg-theme-29 {
  --tw-bg-opacity: 1;
  background-color: rgba(20, 44, 145, var(--tw-bg-opacity));
}

.bg-theme-30 {
  --tw-bg-opacity: 1;
  background-color: rgba(141, 163, 230, var(--tw-bg-opacity));
}

.bg-theme-31 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 216, 216, var(--tw-bg-opacity));
}

.bg-theme-32 {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 89, 152, var(--tw-bg-opacity));
}

.bg-theme-33 {
  --tw-bg-opacity: 1;
  background-color: rgba(74, 179, 244, var(--tw-bg-opacity));
}

.bg-theme-34 {
  --tw-bg-opacity: 1;
  background-color: rgba(81, 127, 164, var(--tw-bg-opacity));
}

.bg-theme-35 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 119, 181, var(--tw-bg-opacity));
}

.bg-theme-36 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 141, 150, var(--tw-bg-opacity));
}

.bg-theme-37 {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 255, var(--tw-bg-opacity));
}

.bg-theme-38 {
  --tw-bg-opacity: 1;
  background-color: rgba(21, 50, 154, var(--tw-bg-opacity));
}

.bg-theme-40 {
  --tw-bg-opacity: 1;
  background-color: rgba(32, 63, 173, var(--tw-bg-opacity));
}

.bg-theme-41 {
  --tw-bg-opacity: 1;
  background-color: rgba(187, 200, 253, var(--tw-bg-opacity));
}

.bg-theme-42 {
  /* rgba(218, 123, 18,1) */
  --tw-bg-opacity: 1;
  background-color: rgba(218, 123, 18, var(--tw-bg-opacity));
}

.bg-theme-43 {
  /* rgba(120, 95, 210,1) */
  --tw-bg-opacity: 1;
  background-color: rgba(120, 95, 210, var(--tw-bg-opacity));
}

.bg-theme-44 {
  /* rgba(0, 161, 199,1) */
  --tw-bg-opacity: 1;
  background-color: rgba(0, 161, 199, var(--tw-bg-opacity));
}

.bg-theme-45 {
  /* rgba(5, 170, 138,1) */
  --tw-bg-opacity: 1;
  background-color: rgba(5, 170, 138, var(--tw-bg-opacity));
}

.bg-dark-1 {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 49, 69, var(--tw-bg-opacity));
}

.bg-dark-2 {
  --tw-bg-opacity: 1;
  background-color: rgba(35, 42, 59, var(--tw-bg-opacity));
}

.bg-dark-3 {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 58, 85, var(--tw-bg-opacity));
}

.bg-dark-4 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 37, 51, var(--tw-bg-opacity));
}

.bg-dark-5 {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 72, 101, var(--tw-bg-opacity));
}

.bg-dark-6 {
  --tw-bg-opacity: 1;
  background-color: rgba(43, 51, 72, var(--tw-bg-opacity));
}

.bg-dark-7 {
  --tw-bg-opacity: 1;
  background-color: rgba(24, 31, 41, var(--tw-bg-opacity));
}
.bg-nav-1 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 86, 254, var(--tw-bg-opacity));
}
.bg-nav-2 {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 125, 255, var(--tw-bg-opacity));
}
.bg-nav-3 {
  --tw-bg-opacity: 1;
  background-color: rgba(92, 171, 255, var(--tw-bg-opacity));
}
.bg-nav-4 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 138, 60, var(--tw-bg-opacity));
}
.bg-input {
  --tw-bg-opacity: 0.2;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-dark-8 {
  background-color: rgba(0, 0, 0, 0.06);
}
.bg-gray-1 {
  /* --tw-bg-opacity: 1; */
  background-color: rgba(25, 31, 37, 0.6);
}
.bg-gray-2 {
  /* rgba(239, 244, 255,1) */
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
}
.bg-gray-3 {
  /* rgba(239, 244, 255,1) */
  --tw-bg-opacity: 1;
  background-color: rgba(238, 238, 238, var(--tw-bg-opacity));
}
.bg-gray-4 {
  /* rgba(242,244,246,1) */
  --tw-bg-opacity: 1;
  background-color: rgba(242, 244, 246, var(--tw-bg-opacity));
}
/** blue **/
.bg-blue-1 {
  /* rgba(0, 86, 254,1) */
  --tw-bg-opacity: 1;
  background-color: rgba(0, 86, 254, var(--tw-bg-opacity));
}
.bg-blue-2 {
  /* --tw-bg-opacity: 0.1; */
  background-color: rgba(0, 86, 254, 0.1);
}
.bg-blue-3 {
  /* --tw-bg-opacity: 0.1; */
  background-color: rgba(237, 245, 255, 1);
}
/** orange **/
.bg-orange-1 {
  /* rgba(255,144,12,1) */
  --tw-bg-opacity: 1;
  background-color: rgba(255, 144, 12, var(--tw-bg-opacity));
}
.bg-orange-2 {
  background-color: rgba(255, 144, 12, 0.1);
}

/** red **/
.bg-red-1 {
  /* rgba(242,86,67,1) */
  --tw-bg-opacity: 1;
  background-color: rgba(242, 86, 67, var(--tw-bg-opacity));
}
.bg-red-2 {
  /* rgba(242,86,67,0.1) */
  --tw-bg-opacity: 1;
  background-color: rgba(242, 86, 67, 0.1);
}
.bg-red-3 {
  --tw-bg-opacity: 1;
  background-color: rgba(211, 45, 44, 1);
}
.bg-tab-1 {
  --tw-bg-opacity: 1;
  background-color: rgba(216, 229, 255, var(--tw-bg-opacity));
}

.text-theme-1 {
  --tw-text-opacity: 1;
  color: rgba(28, 63, 170, var(--tw-text-opacity));
}
.text-theme-6 {
  --tw-text-opacity: 1;
  color: rgba(211, 41, 41, var(--tw-text-opacity));
}
.text-theme-9 {
  --tw-text-opacity: 1;
  color: rgba(145, 199, 20, var(--tw-text-opacity));
}
.text-theme-10 {
  --tw-text-opacity: 1;
  color: rgba(49, 96, 216, var(--tw-text-opacity));
}
.text-theme-11 {
  --tw-text-opacity: 1;
  color: rgba(247, 139, 0, var(--tw-text-opacity));
}
.text-theme-12 {
  --tw-text-opacity: 1;
  color: rgba(59, 185, 129, var(--tw-text-opacity));
}
.text-gray-1 {
  --tw-text-opacity: 1;
  color: rgba(153, 153, 153, var(--tw-bg-opacity));
}
.text-link-1 {
  --tw-text-opacity: 1;
  color: rgba(0, 86, 254, var(--tw-bg-opacity));
}
.text-theme-13 {
  --tw-text-opacity: 1;
  color: rgba(85, 85, 85, , var(--tw-text-opacity));
}
.text-blue-1 {
  /* rgba(0, 86, 254, 1) */
  --tw-bg-opacity: 1;
  color: rgba(0, 86, 254, var(--tw-bg-opacity));
}
.text-blue-3 {
  color: rgba(22, 119, 255, 1);
}
.text-gray-1 {
  /* --tw-bg-opacity: 1; */
  color: rgba(25, 31, 37, 0.6);
}

/** orange **/
.text-orange-1 {
  /* rgba(242,86,67,1) */
  --tw-bg-opacity: 1;
  color: rgba(242, 86, 67, var(--tw-bg-opacity));
}
.text-orange-2 {
  /* rgba(255,144,12,1) */
  --tw-bg-opacity: 1;
  color: rgba(255, 144, 12, var(--tw-bg-opacity));
}
.text-orange-3 {
  /* rgba(255,96,16,1) */
  --tw-bg-opacity: 1;
  color: rgba(255, 96, 16, var(--tw-bg-opacity));
}
.text-orange-4 {
  /* rgba(255,96,16,1) */
  --tw-bg-opacity: 0.4;
  color: rgba(255, 96, 16, var(--tw-bg-opacity));
}
/* .text-gray-1 {
  --tw-text-opacity: 1;
  color: rgba(153, 153, 153, var(--tw-bg-opacity));
} */
/* .text-link-1 {
  --tw-text-opacity: 1;
  color: rgba(28, 63, 170, var(--tw-bg-opacity));
} */

/** green**/
.text-green-1 {
  /* rgba(145,198,27,1) */
  --tw-text-opacity: 1;
  color: rgba(145, 198, 27, var(--tw-bg-opacity));
}
.text-red-1 {
  /* rgba(255,54,55,1) */
  --tw-bg-opacity: 1;
  color: rgba(255, 54, 1, var(--tw-bg-opacity));
}
.text-red-2 {
  /* rgba(211,45,44,1) */
  --tw-bg-opacity: 1;
  color: rgba(211, 45, 44, var(--tw-bg-opacity));
}
.text-opacity-1 {
  --tw-text-opacity: 0.65;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.text-opacity-2 {
  --tw-text-opacity: 0.45;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.border-theme-1 {
  --tw-border-opacity: 0.4;
  border-color: rgba(0, 86, 254, var(--tw-border-opacity));
}
.border-theme-2 {
  --tw-border-opacity: 1;
  border-color: rgba(242, 242, 242, var(--tw-border-opacity));
}
.border-theme-3 {
  border-color: rgba(0, 86, 254, 0.4);
}
.border-theme-4 {
  --tw-border-opacity: 0.1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}
.border-theme-5 {
  --tw-border-opacity: 1;
  border-color: rgba(151, 195, 255, var(--tw-border-opacity));
}
.text-click-1 {
  color: rgba(0, 86, 254, 1);
}
.bg-click-1 {
  background-color: rgba(0, 86, 254, 0.16);
}
.bg-gray-3 {
  --tw-bg-opacity: 0.9;
  background-color: rgba(238, 238, 238, var(--tw-bg-opacity));
}
.bg-gray-4 {
  --tw-bg-opacity: 0.03;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.bg-blue-3 {
  background-color: rgba(239, 244, 255, 1);
}
.bg-blue-4 {
  background-color: rgba(0, 86, 254, 1);
}
