/* purgecss start ignore */
/* @tailwind base; */
/* @tailwind components; */

/* purgecss end ignore */

/* @tailwind utilities; */

/* .input {
  background: rgba(0, 0, 0, 0.03);
  border-width: 1px;
  border-color: transparent;
  font-size: 0.875rem;
  &:focus {
    border-color: #2563eb;
    background: #fff !important;
  }
}
input::-webkit-input-placeholder {
  font-size: 0.8125rem;
}
textarea::-webkit-input-placeholder {
  font-size: 0.8125rem;
} */

.form-input {
  appearance: none;
  -webkit-appearance: none;
  background-color: #fff;
  border-color: #d1d5db;
  border-width: 1px;
  border-radius: 5px;
  padding: 7px 12px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border: none;
  background: rgba(0, 0, 0, 0.03);
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 transparent;
  --tw-ring-shadow: 0 0 transparent;
}
.css-1ct0xl0-control{
  border: none !important;
  background: rgba(0, 0, 0, 0.03) !important;
}
.form-input:focus {
  outline: none;
  --ring-offset-shadow: 0 0 0 var(--ring-offset-width, 0)
    var(--ring-offset-color, #fff);
  --ring-shadow: 0 0 0 calc(0.3px + var(--ring-offset-width, 0px))
    var(--ring-color, #2563eb);
  box-shadow: var(--ring-offset-shadow), var(--ring-shadow),
    var(--box-shadow, 0 0 #0000);
    background:#fff;
}
.form-checkbox {
  -webkit-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #d1d5db;
  border-width: 1px;
  border-radius: 0.25rem;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}
.form-checkbox:focus {
  outline: none;
  --ring-offset-shadow: 0 0 0 var(--ring-offset-width, 2px)
    var(--ring-offset-color, #fff);
  --ring-shadow: 0 0 0 calc(2px + var(--ring-offset-width, 2px))
    var(--ring-color, #2563eb);
  box-shadow: var(--ring-offset-shadow), var(--ring-shadow),
    var(--box-shadow, 0 0 #0000);
}

.form-checkbox:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
